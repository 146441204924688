// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/react-toastify/dist/ReactToastify.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css'); */
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700'); */
/* @import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'; */
/* @import '~admin-lte/dist/css/adminlte.min.css'; */
/* @import './App.min.css'; */

#root {
  width: 100vw;
  height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,wFAAwF;AAExF,6FAA6F;AAI7F,4EAA4E;AAC5E,oDAAoD;AACpD,6BAA6B;;AAI7B;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,eAAe;AACjB","sourcesContent":["/* @import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css'); */\n@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');\n/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700'); */\n@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');\n\n@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';\n/* @import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'; */\n/* @import '~admin-lte/dist/css/adminlte.min.css'; */\n/* @import './App.min.css'; */\n\n@import '~react-toastify/dist/ReactToastify.min.css';\n\n#root {\n  width: 100vw;\n  height: 100vh;\n}\n\n.main-header .navbar-nav .nav-item .nav-link {\n  background: transparent;\n  border: none;\n  outline: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
