import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {NavDropdown} from 'react-bootstrap';
import cookies from 'js-cookie';

export interface Language {
  key: string;
  icon: string;
  label: string;
}

const languages: Language[] = [
  {
    key: 'en',
    icon: 'flag-icon-us',
    label: 'header.language.english'
  },
  {
    key: 'th',
    icon: 'flag-icon-th',
    label: 'header.language.thai'
  }
];

const LanguagesDropdown = () => {
  const {t, i18n} = useTranslation();

  const currentLanguageCode = cookies.get('lng') || 'en';
  const currentLanguage = languages.find((l) => l.key === currentLanguageCode);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    changeLanguage((typeof currentLanguage !== 'undefined') ? currentLanguage.key : "en");
  }, []);

  const getCurrentLanguage = (): Language => {
    const currentLanguage = i18n.language;
    console.log(currentLanguage);
    if (currentLanguage) {
      const language = languages.find(
        (language: Language) => language.key === currentLanguage
      );
      return language || languages[0];
    }
    return languages[0];
  };

  return (
    <NavDropdown
      title={
        <div style={{display: 'inline-block'}}>
          <i className={`flag-icon ${getCurrentLanguage().icon}`} />
        </div>
      }
      id="collasible-nav-dropdown"
    >
      {languages.map((language) => (
        <NavDropdown.Item
          onClick={() => {
            changeLanguage(language.key);
            cookies.set('lng', language.key);
            // setDropdownOpen(false);
          }}
          key={language.key}
        >
          <i className={`flag-icon ${language.icon} mr-2`} />
          <span>{t<string>(language.label)}</span>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default LanguagesDropdown;
