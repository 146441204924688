import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {loginUser} from '@store/reducers/auth';
import {setWindowClass} from '@app/utils/helpers';
import {PfButton, PfCheckbox} from '@profabric/react-components';
import useCollapse from 'react-collapsed';
import Modal from 'react-bootstrap/Modal';
import {JSEncrypt} from 'jsencrypt';

import * as Yup from 'yup';

import {Button, Form, InputGroup} from 'react-bootstrap';
import * as AuthService from '../../services/auth';
import Navmenu from './Navmenu';
import {text} from 'stream/consumers';

const Login = () => {
  const [svgId, setSvgId] = useState('aaaaaa');
  const [svg, setSvg] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [isValidCaptcha, setIsValidCaptcha] = useState('');
  const [account, setAccount] = useState({
    email: '',
    password: ''
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [isExpanded, setExpanded] = useState(
    searchParams.get('serviceid') !== '8002'
  );
  const {getToggleProps, getCollapseProps} = useCollapse({
    isExpanded
  });

  const [isExpandedDealer, setExpandedDealer] = useState(
    searchParams.get('serviceid') === '8002'
  );
  const {getToggleProps: getToggleProps2, getCollapseProps: getCollapseProps2} =
    useCollapse({
      isExpanded: isExpandedDealer
    });

  const [serviceid, setServiceid] = useState(
    searchParams.get('serviceid') || '0000'
  );
  const [binding, setBinding] = useState(searchParams.get('binding') || '');
  const [uuid, setUuid] = useState(searchParams.get('uuid') || '');
  const [SAMLRequest, setSAMLRequest] = useState(
    searchParams.get('SAMLRequest') || ''
  );
  const [RelayState, setRelayState] = useState(
    searchParams.get('RelayState') || ''
  );
  const [app_id, setApp_id] = useState(searchParams.get('app_id') || '');
  const [state, setState] = useState(searchParams.get('state') || '');
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
  const [showMd, setShowMd] = useState(false);
  const [showMdCaptcha, setShowMdCaptcha] = useState(false);
  const [is365AuthLoading, set365AuthLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [t] = useTranslation();

  useEffect(() => {
    // loadSvg();
    // setServiceid(searchParams.get('serviceid') as string);
  }, []);

  // setServiceid(searchParams.get('serviceid') as string);

  const loadSvg = async () => {
    try {
      const apiSvg = await fetch(
        'https://api-passport.advanceagro.net/oauth/idms/captcha'
      );
      console.log(apiSvg);
      const textSvg = await apiSvg.text();
      setSvg(textSvg);
    } catch {}
  };

  const checkBanOrMultipleWrong = async (email: string, password: string) => {
    try {
      const service = app_id !== '' ? app_id : serviceid;
      const data = await AuthService.checkStatusUser(email, service);
      // console.log(data);
      if (data.status === true) {
        await login(email, password);
      } else {
        setModalTitle(data.title);
        if (data.code === 'BAN') {
        }
        if (data.code === 'CAPTCHA') {
          setShowMdCaptcha(true);
          setAccount({
            email: email,
            password: password
          });
        }
      }
    } catch (error: any) {
      setAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const validateCaptcha = async () => {
    try {
      const data = await AuthService.checkCaptcha(captcha);
      if (data.valid) {
        await login(account.email, account.password);
        setShowMdCaptcha(false);
        setCaptcha('');
      } else {
        setIsValidCaptcha(data.error);
        setIsLoaded(true);
        setCaptcha('');
        setTimeout(function () {
          setIsLoaded(false);
          setSvgId(randomId());
        }, 1000);
      }
    } catch {}
  };

  const login = async (email: string, password: string) => {
    try {
      //RSA
      const encrypt = new JSEncrypt();
      const pub_key = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBt/cKQ43LoiQ0gk3e2tkxtx+C
MWBw68mpbO2nkOycWLung5nu47I0pC8uhTyxMaLUEpGXwVOLs8fqmZdClmwplPsm
cZZKcrTUS6n4TjP7+HPwnCxKlCv0099E8UZPNMs/QTEq2kOYFdT5bV4DtFHt9TbL
duzwZYh1YnjpjWDgkQIDAQAB
-----END PUBLIC KEY-----`;
      encrypt.setPublicKey(pub_key);
      const password_ciphertext = encrypt.encrypt(password);
      console.log(password_ciphertext);

      //setServiceid(searchParams.get('serviceid') as string);
      setAuthLoading(true);
      //app_id
      const service = app_id !== '' ? app_id : serviceid;
      const _SAMLRequest =
        service === '8001' || service === '7002' || service === '8004'
          ? `&SAMLRequest=${encodeURIComponent(SAMLRequest)}`
          : '';
      const _binding =
        service === '8001' || service === '7002' || service === '8004'
          ? `&binding=${encodeURIComponent(binding)}`
          : '';
      const _uuid =
        service === '8001' || service === '7002' || service === '8004'
          ? `&uuid=${encodeURIComponent(uuid)}`
          : '';
      const _RelayState =
        service === '8001' || service === '7002' || service === '8004'
          ? `&RelayState=${encodeURIComponent(RelayState)}`
          : '';
      // const data = await AuthService.loginByAuth(
      //   email,
      //   password,
      //   service,
      //   state
      // );
      const data = await AuthService.loginByAuth(
        email,
        password_ciphertext ? password_ciphertext : '',
        service,
        state
      );
      toast.success('Login is succeed!');
      setAuthLoading(false);
      dispatch(loginUser(data.token));
      let hash =
        '&signed_request=' +
        encodeURIComponent(data.hash.replace(/-/g, '=').replace(/@/g, '+'));
      // let hash = "&signed_request="+encodeURIComponent(data.hash);
      let url = Buffer.from(data.redirect, 'base64')
        .toString('utf-8')
        .concat(hash);
      //navigate(url);
      setTimeout(
        () =>
          (window.location.href = `${url}&t=${data.token}${_SAMLRequest}${_binding}${_uuid}${_RelayState}`),
        1000
      );
      //window.location.href = url;
    } catch (error: any) {
      setAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const loginByGoogle = async () => {
    try {
      setGoogleAuthLoading(true);
      const token = await AuthService.loginByGoogle();
      toast.success('Login is succeeded!');
      setGoogleAuthLoading(false);
      dispatch(loginUser(token));
      navigate('/');
    } catch (error: any) {
      setGoogleAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const loginBy365 = async () => {
    try {
      //setServiceid(searchParams.get('serviceid') as string);
      set365AuthLoading(true);
      const service = app_id !== '' ? app_id : serviceid;
      const _SAMLRequest =
        service === '8001' || service === '7002' || service === '8004'
          ? `&SAMLRequest=${encodeURIComponent(SAMLRequest)}`
          : '';
      const _binding =
        service === '8001' || service === '7002' || service === '8004'
          ? `&binding=${encodeURIComponent(binding)}`
          : '';
      const _uuid =
        service === '8001' || service === '7002' || service === '8004'
          ? `&uuid=${encodeURIComponent(uuid)}`
          : '';
      const _RelayState =
        service === '8001' || service === '7002' || service === '8004'
          ? `&RelayState=${encodeURIComponent(RelayState)}`
          : '';
      const params = await AuthService.loginBy365(service, state);
      console.log(params.token);
      toast.success('Login is succeeded!');
      set365AuthLoading(false);
      dispatch(loginUser(params.token));
      let hash =
        '&signed_request=' +
        encodeURIComponent(params.hash.replace(/-/g, '=').replace(/@/g, '+'));
      let url = Buffer.from(params.redirect, 'base64')
        .toString('utf-8')
        .concat(hash);
      console.log(url);
      setTimeout(
        () =>
          (window.location.href = `${url}&t=${params.token}${_SAMLRequest}${_binding}${_uuid}${_RelayState}`),
        1000
      );
    } catch (error: any) {
      set365AuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const loginBy365Dealer = async () => {
    try {
      set365AuthLoading(true);
      const _SAMLRequest = `&SAMLRequest=${encodeURIComponent(SAMLRequest)}`;
      const _binding = `&binding=${encodeURIComponent(binding)}`;
      const _uuid = `&uuid=${encodeURIComponent(uuid)}`;
      const _RelayState = `&RelayState=${encodeURIComponent(RelayState)}`;
      const params = await AuthService.loginBy365Dealer('8002', state);
      console.log(params.token);
      toast.success('Login is succeeded!');
      set365AuthLoading(false);
      dispatch(loginUser(params.token));
      let hash =
        '&signed_request=' +
        encodeURIComponent(params.hash.replace(/-/g, '=').replace(/@/g, '+'));
      let url = Buffer.from(params.redirect, 'base64')
        .toString('utf-8')
        .concat(hash);
      console.log(url);
      setTimeout(
        () =>
          (window.location.href = `${url}&t=${params.token}${_SAMLRequest}${_binding}${_uuid}${_RelayState}`),
        1000
      );
    } catch (error: any) {
      set365AuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const randomId = function (length = 6) {
    return Math.random()
      .toString(36)
      .substring(2, length + 2);
  };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      // email: Yup.string().email('Invalid email address').required('Required'),
      email: Yup.string().required('Required'),
      password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
      // login(values.email, values.password);
      checkBanOrMultipleWrong(values.email, values.password);
    }
  });

  setWindowClass('hold-transition login-page2');

  return (
    <>
      <Modal
        show={showMd && serviceid === '8001'}
        backdrop="static"
        // className={'modal-fullscreen'}
      >
        <Modal.Header>
          <Modal.Title>กรุณาเลือกเมนู</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row d-flex justify-content-center m-3">
            <Button
              className="col-8"
              variant="primary"
              onClick={() => {
                setShowMd(false);
                setExpanded(true);
                setExpandedDealer(false);
              }}
            >
              เข้าระบบด้วยสิทธิพนักงาน aA
            </Button>
          </div>
          <div className="row d-flex justify-content-center m-3">
            <Button
              className="col-8"
              variant="success"
              onClick={() => {
                setShowMd(false);
                setExpanded(false);
                setExpandedDealer(true);
              }}
            >
              เข้าระบบด้วยสิทธิดีลเลอร์
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showMdCaptcha} backdrop="static">
        <Modal.Header>
          <Modal.Title>
            เราพบว่าท่านกรอกรหัสผ่านผิดหลายครั้ง
            <br />
            กรุณาใส่อักขระเพื่อยืนยัน
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row d-flex justify-content-center m-3">
            <div>
              {!isLoaded ? (
                <img
                  id={svgId}
                  style={{border: '1px solid black'}}
                  // src={`http://localhost:3001/oauth/idms/captcha?${svgId}`}
                  src={`https://api-passport.advanceagro.net/oauth/idms/captcha?${svgId}`}
                />
              ) : (
                <PfButton
                  theme="secondary"
                  block
                  type="submit"
                  loading={true}
                  disabled={true}
                ></PfButton>
              )}
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control text-center"
                  name="captcha"
                  value={captcha}
                  onChange={(e) => {
                    setCaptcha(e.target.value);
                  }}
                />
              </div>
              <Button
                className="col-12"
                variant="primary"
                onClick={() => {
                  validateCaptcha();
                  // setIsLoaded(true);
                  // setTimeout(function () {
                  //   setIsLoaded(false);
                  //   setSvgId(randomId());
                  // }, 1000);
                }}
              >
                ยืนยัน
              </Button>
              <div className="flex flex-col mt-4 text-center">
                <span className="text-danger">{isValidCaptcha}</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Navmenu></Navmenu>
      <div className="login-box">
        <div className="card card-outline card-primary" {...getCollapseProps()}>
          <div className="card-header text-center">
            <div className="logo">
              <img
                src="https://api-passport.advanceagro.net/img/aa.png"
                alt=""
              />
            </div>
            <div className="h4">DoubleA Passport</div>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t<string>('login.label.signIn')}</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Control
                    id="email"
                    name="email"
                    placeholder="Username (NOT EMAIL)"
                    onChange={handleChange}
                    value={values.email}
                    // isValid={touched.email && !errors.email}
                    // isInvalid={touched.email && !!errors.email}
                  />
                  {touched.email && errors.email ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  ) : (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <i className="fas fa-user" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                </InputGroup>
              </div>
              <div className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onKeyUp={(event) => {
                      if (event.code === 'Enter') {
                        event.preventDefault();
                        // login(values.email, values.password);
                        checkBanOrMultipleWrong(values.email, values.password);
                        //document.querySelector('form')!.submit();
                      }
                    }}
                    onChange={handleChange}
                    value={values.password}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  {touched.password && errors.password ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  ) : (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <i className="fas fa-lock" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                </InputGroup>
              </div>

              <div className="row">
                <div className="col-5 invisible">
                  <PfCheckbox checked={false}>
                    {t<string>('login.label.rememberMe')}
                  </PfCheckbox>
                </div>
                <div className="col-7">
                  <PfButton
                    theme="success"
                    block
                    type="submit"
                    loading={isAuthLoading}
                    disabled={
                      isFacebookAuthLoading ||
                      isGoogleAuthLoading ||
                      is365AuthLoading
                    }
                  >
                    {t<string>('login.button.signIn.social', {what: 'IDMS'})}
                  </PfButton>
                </div>
              </div>
            </form>
            <hr></hr>
            <div className="social-auth-links text-center mt-2 mb-3">
              <PfButton
                block
                className="mb-2"
                onClick={loginBy365}
                loading={is365AuthLoading}
                disabled={isAuthLoading || isGoogleAuthLoading}
              >
                <img
                  className="mr-2 mb-1"
                  width={'16px;'}
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgaGVpZ2h0PSIzMiIgc3R5bGU9Im92ZXJmbG93OnZpc2libGU7ZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMiAzMiIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxnIGlkPSJNaWNyb3NvZnRfMV8iPjxnIGlkPSJNaWNyb3NvZnQiPjxnIGlkPSJCbHVlX3g1Rl9TcXVhcmUiPjxyZWN0IGhlaWdodD0iMTUiIHN0eWxlPSJmaWxsOiM1MUFFRDk7IiB3aWR0aD0iMTUiIHk9IjE3Ii8+PC9nPjxnIGlkPSJZZWxsb3dfeDVGX1NxdWFyZSI+PHJlY3QgaGVpZ2h0PSIxNSIgc3R5bGU9ImZpbGw6I0ZFQzMyNzsiIHdpZHRoPSIxNSIgeD0iMTciIHk9IjE3Ii8+PC9nPjxnIGlkPSJHcmVlbl94NUZfU3F1YXJlIj48cmVjdCBoZWlnaHQ9IjE1IiBzdHlsZT0iZmlsbDojMzRCNjdBOyIgd2lkdGg9IjE1IiB4PSIxNyIvPjwvZz48ZyBpZD0iT3JhbmdlX3g1Rl9TcXVhcmUiPjxyZWN0IGhlaWdodD0iMTUiIHN0eWxlPSJmaWxsOiNGMTU3MjM7IiB3aWR0aD0iMTUiLz48L2c+PC9nPjwvZz48L2c+PC9zdmc+"
                />
                {/* <i className="fab fa-microsoft mr-2" /> */}
                {t<string>('login.button.signIn.social', {
                  what: '365'
                })}
              </PfButton>
            </div>
            <p className="mb-1">
              <a
                href="https://api-passport.advanceagro.net/oauth/idms/reset-password"
                target={'_blank'}
              >
                {t<string>('login.label.forgotPass')}
              </a>
              {/* <Link to="/forgot-password">
              {t<string>('login.label.forgotPass')}
            </Link> */}
            </p>
            <p className="mb-0">
              <a
                href="https://passwordreset.microsoftonline.com"
                target={'_blank'}
              >
                {t<string>('login.label.forgotPass365')}
              </a>
            </p>
          </div>
        </div>
        <div
          className="card card-outline card-primary"
          {...getCollapseProps2()}
        >
          <div className="card-header text-center">
            <div className="logo">
              <img
                src="https://api-passport.advanceagro.net/img/aa.png"
                alt=""
              />
            </div>
            <div className="h4">DoubleA Passport</div>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t<string>('login.label.signIn')}</p>
            <div
              className={
                serviceid === '8002'
                  ? 'social-auth-links text-center mt-2 mb-3'
                  : 'd-none'
              }
            >
              <PfButton
                theme="info"
                block
                className="mb-2"
                onClick={loginBy365Dealer}
                loading={is365AuthLoading}
                disabled={isAuthLoading || isGoogleAuthLoading}
              >
                <img
                  className="mr-2 mb-1"
                  width={'16px;'}
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgaGVpZ2h0PSIzMiIgc3R5bGU9Im92ZXJmbG93OnZpc2libGU7ZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMiAzMiIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxnIGlkPSJNaWNyb3NvZnRfMV8iPjxnIGlkPSJNaWNyb3NvZnQiPjxnIGlkPSJCbHVlX3g1Rl9TcXVhcmUiPjxyZWN0IGhlaWdodD0iMTUiIHN0eWxlPSJmaWxsOiM1MUFFRDk7IiB3aWR0aD0iMTUiIHk9IjE3Ii8+PC9nPjxnIGlkPSJZZWxsb3dfeDVGX1NxdWFyZSI+PHJlY3QgaGVpZ2h0PSIxNSIgc3R5bGU9ImZpbGw6I0ZFQzMyNzsiIHdpZHRoPSIxNSIgeD0iMTciIHk9IjE3Ii8+PC9nPjxnIGlkPSJHcmVlbl94NUZfU3F1YXJlIj48cmVjdCBoZWlnaHQ9IjE1IiBzdHlsZT0iZmlsbDojMzRCNjdBOyIgd2lkdGg9IjE1IiB4PSIxNyIvPjwvZz48ZyBpZD0iT3JhbmdlX3g1Rl9TcXVhcmUiPjxyZWN0IGhlaWdodD0iMTUiIHN0eWxlPSJmaWxsOiNGMTU3MjM7IiB3aWR0aD0iMTUiLz48L2c+PC9nPjwvZz48L2c+PC9zdmc+"
                />
                {/* <i className="fab fa-microsoft mr-2" /> */}
                {'Sign in 365 Dealer'}
              </PfButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
