import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import detector from "i18next-browser-languagedetector";

import translationEN from '../locales/en/translation.json';
import translationTR from '../locales/tr/translation.json';
import translationDE from '../locales/de/translation.json';
import translationES from '../locales/es/translation.json';
import translationFR from '../locales/fr/translation.json';
import translationTH from '../locales/th/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  de: {
    translation: translationDE
  },
  th: {
    translation: translationTH
  }
  // ,
  // "th-TH": {
  //   translation: translationTH
  // },
  // "en-US": {
  //   translation: translationEN
  // }
};

i18n
  .use(detector)
  .use(initReactI18next as any) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'th',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    react: {
      wait: true
    }
  } as any);

export default i18n;
