import {removeWindowClass} from '@app/utils/helpers';
import { rejects } from 'assert';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import { reloadResources } from 'i18next';
import { resolve } from 'path';

export const loginBy365 = async (serviceid: string, state: string) => {
  const params = await Gatekeeper.loginBy365(serviceid, state);
  localStorage.setItem('token', params.token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return params;
};

export const loginBy365Dealer = async (serviceid: string, state: string) => {
  const params = await Gatekeeper.loginDealer(serviceid, state);
  localStorage.setItem('token', params.token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return params;
};

export const unlink365 =  () => {
  Gatekeeper.unlink365();
};

export const manage365 =  () => {
  Gatekeeper.manage365();
};

export const loginByAuth = async (
  email: string,
  password: string,
  serviceid: string,
  state: string
) => {
  const data = await Gatekeeper.loginByIdms(email, password, serviceid, state);
  console.log(data.token);
  localStorage.setItem('token', data.token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return data;
};

export const registerByAuth = async (email: string, password: string) => {
  const token = await Gatekeeper.registerByAuth(email, password);
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};

export const loginByGoogle = async () => {
  const token = await Gatekeeper.loginByGoogle();
  localStorage.setItem('token', token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return token;
};

export const registerByGoogle = async () => {
  const token = await Gatekeeper.registerByGoogle();
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};

export const loginByFacebook = async () => {
  const token = await Gatekeeper.loginByFacebook();
  localStorage.setItem('token', token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return token;
};

export const registerByFacebook = async () => {
  const token = await Gatekeeper.registerByFacebook();
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};

export const checkStatusUser = async (email: any, serviceid: any) => {
  const res = await axios.post(
    `${url}/oauth/idms/status`,
    {
      email,
      serviceid
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = res.data;
  return data;
};

export const checkCaptcha = async (captcha: any) => {
  const res = await axios.post(
    `${url}/oauth/idms/captcha`,
    {
      captcha
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }
  );
  const data = res.data;
  return data;
};
