import React from 'react';
import {useTranslation} from 'react-i18next';
import {Navbar, Nav, Container} from 'react-bootstrap';
import LanguagesDropdown from '@app/modules/main/header/languages-dropdown/LanguagesDropdown2';
import {toast} from 'react-toastify';
import * as AuthService from '../../services/auth';
function Navmenu() {
  const [t] = useTranslation();

  const manage365 = async () => {
    try {
      await AuthService.manage365();
    } catch (error: any) {
    }
  };

  const unlink365 = async () => {
    try {
      await AuthService.unlink365();
    } catch (error: any) {
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">aA Passport</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="me-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav> */}

          <Nav className="justify-content-end" style={{width: '100%'}}>
            <LanguagesDropdown />
            <Nav.Link eventKey={2} onClick={manage365}>
              {/* <i className="fas fa-sliders-h mx-2" /> */}
              {t<string>('login.label.manage365')}
            </Nav.Link>
            <Nav.Link eventKey={2} onClick={unlink365}>
              {/* <i className="fas fa-sliders-h mx-2" /> */}
              {t<string>('login.label.unlink')}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navmenu;
