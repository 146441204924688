import React, {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {PfImage} from '@profabric/react-components';
import {addWindowClass, removeWindowClass, sleep} from '@app/utils/helpers';

function Logout() {
  const [searchParams, setSearchParams] = useSearchParams();
  async function getLogoutUrl(): Promise<string> {
    try {
      const serviceid = searchParams.get('serviceid') || '0000';
      const response = await fetch(
        `https://api-passport.advanceagro.net/oauth/idms/signout?serviceid=${serviceid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response);
      const data = await response.json();
      let url = Buffer.from(data.redirect, 'base64').toString('utf-8');
      return url;
    } catch (error) {
      console.log(error);
      return 'https://advanceagro.net';
    }
  }

  useEffect(() => {
    const doMe = async () => {
      const url = await getLogoutUrl();
      window.location.href = url;
    };
    doMe();
  }, []);

  return (
    <div className="preloader flex-column justify-content-center align-items-center">
      <PfImage
        className="animation__shake"
        src="/img/logo.png"
        alt="AdminLTELogo"
        height={60}
        width={60}
      />
    </div>
  );
}

export default Logout;
