import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {loginUser} from '@store/reducers/auth';
import {setWindowClass} from '@app/utils/helpers';
import {PfButton, PfCheckbox} from '@profabric/react-components';

import * as Yup from 'yup';

import {Form, InputGroup} from 'react-bootstrap';
import * as AuthService from '../../services/auth';
import Navmenu from './Navmenu';

const Dealer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceid, setServiceid] = useState(
    searchParams.get('serviceid') || '8002'
  );
  const [app_id, setApp_id] = useState(searchParams.get('app_id') || '');
  const [state, setState] = useState(searchParams.get('state') || '');
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
  const [is365AuthLoading, set365AuthLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [t] = useTranslation();

  // useEffect(() => {
  //   setServiceid(searchParams.get('serviceid') as string);
  // }, []);

  // setServiceid(searchParams.get('serviceid') as string);

  const login = async (email: string, password: string) => {
    try {
      //setServiceid(searchParams.get('serviceid') as string);
      setAuthLoading(true);
      //app_id
      const service = app_id !== '' ? app_id : serviceid;
      const data = await AuthService.loginByAuth(
        email,
        password,
        service,
        state
      );
      toast.success('Login is succeed!');
      setAuthLoading(false);
      dispatch(loginUser(data.token));
      let hash =
        '&signed_request=' +
        encodeURIComponent(data.hash.replace(/-/g, '=').replace(/@/g, '+'));
      // let hash = "&signed_request="+encodeURIComponent(data.hash);
      let url = Buffer.from(data.redirect, 'base64')
        .toString('utf-8')
        .concat(hash);
      //navigate(url);
      setTimeout(() => (window.location.href = `${url}&t=${data.token}`), 1000);
      //window.location.href = url;
    } catch (error: any) {
      setAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const loginByGoogle = async () => {
    try {
      setGoogleAuthLoading(true);
      const token = await AuthService.loginByGoogle();
      toast.success('Login is succeeded!');
      setGoogleAuthLoading(false);
      dispatch(loginUser(token));
      navigate('/');
    } catch (error: any) {
      setGoogleAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const loginBy365Dealer = async () => {
    try {
      //setServiceid(searchParams.get('serviceid') as string);
      set365AuthLoading(true);
      const service = app_id !== '' ? app_id : serviceid;
      const params = await AuthService.loginBy365Dealer(service, state);
      console.log(params.token);
      toast.success('Login is succeeded!');
      set365AuthLoading(false);
      dispatch(loginUser(params.token));
      let hash =
        '&signed_request=' +
        encodeURIComponent(params.hash.replace(/-/g, '=').replace(/@/g, '+'));
      //let hash = "&signed_request="+encodeURIComponent(params.hash);
      let url = Buffer.from(params.redirect, 'base64')
        .toString('utf-8')
        .concat(hash);
      console.log(url);
      //navigate(url);
      setTimeout(
        () => (window.location.href = `${url}&t=${params.token}`),
        1000
      );
      //window.location.href = url;
    } catch (error: any) {
      set365AuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      // email: Yup.string().email('Invalid email address').required('Required'),
      email: Yup.string().required('Required'),
      password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
      login(values.email, values.password);
    }
  });

  setWindowClass('hold-transition login-page2');

  return (
    <>
      <Navmenu></Navmenu>
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <div className="logo">
              <img
                src="https://api-passport.advanceagro.net/img/aa.png"
                alt=""
              />
            </div>
            <div className="h4">DoubleA Passport</div>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t<string>('login.label.signIn')}</p>
            <div className="social-auth-links text-center mt-2 mb-3">
              <PfButton
                block
                className="mb-2"
                onClick={loginBy365Dealer}
                loading={is365AuthLoading}
                disabled={isAuthLoading || isGoogleAuthLoading}
              >
                <img
                  className="mr-2 mb-1"
                  width={'16px;'}
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgaGVpZ2h0PSIzMiIgc3R5bGU9Im92ZXJmbG93OnZpc2libGU7ZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMiAzMiIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxnIGlkPSJNaWNyb3NvZnRfMV8iPjxnIGlkPSJNaWNyb3NvZnQiPjxnIGlkPSJCbHVlX3g1Rl9TcXVhcmUiPjxyZWN0IGhlaWdodD0iMTUiIHN0eWxlPSJmaWxsOiM1MUFFRDk7IiB3aWR0aD0iMTUiIHk9IjE3Ii8+PC9nPjxnIGlkPSJZZWxsb3dfeDVGX1NxdWFyZSI+PHJlY3QgaGVpZ2h0PSIxNSIgc3R5bGU9ImZpbGw6I0ZFQzMyNzsiIHdpZHRoPSIxNSIgeD0iMTciIHk9IjE3Ii8+PC9nPjxnIGlkPSJHcmVlbl94NUZfU3F1YXJlIj48cmVjdCBoZWlnaHQ9IjE1IiBzdHlsZT0iZmlsbDojMzRCNjdBOyIgd2lkdGg9IjE1IiB4PSIxNyIvPjwvZz48ZyBpZD0iT3JhbmdlX3g1Rl9TcXVhcmUiPjxyZWN0IGhlaWdodD0iMTUiIHN0eWxlPSJmaWxsOiNGMTU3MjM7IiB3aWR0aD0iMTUiLz48L2c+PC9nPjwvZz48L2c+PC9zdmc+"
                />
                {/* <i className="fab fa-microsoft mr-2" /> */}
                {t<string>('login.button.signIn.social', {
                  what: '365'
                })}
              </PfButton>
            </div>
            <p className="mb-0">
              <a
                href="https://passwordreset.microsoftonline.com"
                target={'_blank'}
              >
                {t<string>('login.label.forgotPass365')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dealer;
